.navbarEn{
  background-color:transparent;
  height: 8vh;
  margin-left: 14%;
}

.navbarAr{
  background-color:transparent;
  height: 8vh;
  margin-right: 14%;
}

.rbc-event {
   border: 1px solid #EFE3D7 !important;
   border-radius: 4px;
   padding: 5px;
   background-color: #FEF9F6;
   color: #864629;
   font-weight: 400;
   font-size: 12px;
   height: 24px !important;
   margin-top: 5px !important;
   left: 0 !important;
   /* Force all events to start from the left */
   width: 100% !important;
   /* Make the events span the full width */
}

.rbc-time-content {
   background-color: #FFFFFF;
   height: auto !important;
   /* Allow the main content area to grow dynamically */
   border: 1px solid #EFE3D7 !important;
}

.rbc-timeslot-group {
   min-height: 60px;
   /* Base height for a time slot */
   height: auto !important;
   /* Allow height to adjust based on content */
   max-height: none !important;
   /* Remove any maximum height constraints */
   overflow: visible;
   /* Ensure content is visible */
}

.rbc-time-header {
   display: none;
}

.rbc-day-slot .rbc-event {
   border: 1px solid #EFE3D7 !important;
}

.rbc-time-view {
   border: none !important;
}

.rbc-toolbar {
   display: none;
   /* Hide default toolbar */
}

.rbc-day-slot .rbc-events-container {
   bottom: 0;
   left: 0;
   position: absolute;
   right: 0;
   margin-right: 0;
   top: 0;
}

.rbc-events-container {
   margin-left: 20px !important;
   margin-right: 20px !important;
}

.rbc-time-content>*+*>* {
   border-left: none !important;
}

// .sunday {
//    color: #292D32 !important;
// }

// .saturday {
//    color: #292D32 !important;
// }
.sunday-highlight {
   background-color: transparent;
   /* Light red */
   color: #3C3C3C;
   /* Darker red */
}

.saturday-highlight {
   background-color: transparent;
   /* Light blue */
   color: #3C3C3C;
   /* Darker blue */
}

.today {
   background-color: transparent !important;
   color: #864629 !important;
   border: 1px solid #864629 !important;
   height: 40px !important;
}

.selected {
   background-color: #864629 !important;
   color: #FFFFFF !important;
   height: 40px !important;
}

.react-calendar__tile {
   //    margin: 0.5px !important;
   transition: all 0.3s ease !important;
}

.react-calendar {
   border: 1px solid #EFE3D7 !important;
   box-shadow: none !important;
   width: 100% !important;
}

.react-calendar__month-view__weekdays__weekday {
   color: #864629 !important;
}

.calendar-container {
   .react-calendar__tile:disabled {
      background-color: transparent !important;
   }

}

.size-padding-large {
   @media screen and (max-width:1200px) {
      margin-top: 20px !important;
      padding: 0px !important;
   }
}
@tailwind base;
@tailwind components;
@tailwind utilities;

/* ////////////////////////// OutFit Font ////////////////// */

@font-face {
    font-family: Extra_light;
    src: url('./assets/fonts/Outfit-ExtraLight.ttf');
}

@font-face {
    font-family: outfit_thin;
    src: url('./assets/fonts/Outfit-Thin.ttf');
}

@font-face {
    font-family: outfit_light;
    src: url('./assets/fonts/Outfit-Light.ttf');
}

@font-face {
    font-family: outfit_regular;
    src: url('./assets/fonts/Outfit-Regular.ttf');
}

@font-face {
    font-family: outfit_medium;
    src: url('./assets/fonts/Outfit-Medium.ttf');
}

@font-face {
    font-family: outfit_semiBold;
    src: url('./assets/fonts/Outfit-SemiBold.ttf');
}

@font-face {
    font-family: outfit_bold;
    src: url('./assets/fonts/Outfit-Bold.ttf');
}

@font-face {
    font-family: outfit_extraBold;
    src: url('./assets/fonts/Outfit-ExtraBold.ttf');
}

@font-face {
    font-family: outfit_black;
    src: url('./assets/fonts/Outfit-Black.ttf');
}
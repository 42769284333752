.landing-image {
    background-image: url("../images/WithoutLogin/landing-image-2.svg");
    background-repeat: no-repeat;
    width: 100% !important;
    height: fit-content !important;
    min-height: 100vh;
    background-size: cover;
    background-position: center;
}

.commonEnContainer {
    height: 92vh;
    overflow-y: auto;
    padding: 30px !important;
}

.commonArContainer {
    height: 92vh;
    overflow-y: auto;
    padding: 30px !important;
}

.listCustomHeader {
    background-color: #FFFFFF;
    border-bottom: 1px solid #EFE3D7;
    height: 38px;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
}

.listAppointment {
    height: 80px !important;
    border-bottom: 1px solid #EFE3D7 !important;
    padding: 4px 8px !important;
    background-color: #FFFFFF;
}

.listAppointmentProfile {
    height: 58px !important;
    border-bottom: 1px solid #EFE3D7 !important;
    padding: 4px 8px !important;
    background-color: #FFFFFF;
}

///back-ground colour////

.bColor-EFE3D7 {
    border-color: #EFE3D7 !important;
}

.bColor-D6DDE6 {
    border-color: #D6DDE6 !important;
}

.bc-FFFFFF {
    background-color: #FFFFFF !important;
}

.bc-FEF9F6 {
    background-color: #FEF9F6;
}

.h-132 {
    height: 132px !important;
}

.fs-12 {
    font-size: 12px;
}

.fs-14 {
    font-size: 14px;
}

.fs-16 {
    font-size: 16px;
}

.fs-17 {
    font-size: 17px;
}

.fs-18 {
    font-size: 18px;
}

.fs-20 {
    font-size: 20px;
}

.fs-24 {
    font-size: 24px;
}

.fs-28 {
    font-size: 28px;
}

.fs-32 {
    font-size: 32px;
}

.f-40 {
    font-size: 40px;
}

.fc-3C3C3C {
    color: #3C3C3C;
}

.fc-998A7B {
    color: #998A7B;
}

.fc-7F7F7F {
    color: #7F7F7F;
}

.fc-404040 {
    color: #404040;
}

.fc-202224 {
    color: #202224;
}

.fc-292D32 {
    color: #292D32;
}

.fc-C56616 {
    color: #C56616;
}

.fc-A4AAB2 {
    color: #A4AAB2;
}

.fc-A2A2A2 {
    color: #A2A2A2;
}

.fc-864629 {
    color: #864629;
}

.fc-5B88E9 {
    color: #5B88E9;
}

.fc-1E1E1E {
    color: #1E1E1E;
}

.lh-14 {
    line-height: 14px;
}

.lh-1512 {
    line-height: 15.12px;
}

.lh-16 {
    line-height: 16px;
}

.lh-176 {
    line-height: 17.64px;
}

.lh-18 {
    line-height: 18px;
}

.lh-196 {
    line-height: 19.6px;
}

.lh-20 {
    line-height: 20px;
}

.lh-201 {
    line-height: 20.16px;
}

.lh-208 {
    line-height: 20.8px;
}

.lh-2068 {
    line-height: 20.68px;
}

.lh-21 {
    line-height: 21px;
}

.lh-2268 {
    line-height: 22.68px;
}

.lh-23 {
    line-height: 23.4px;
}

.lh-24 {
    line-height: 24px;
}

.lh-252 {
    line-height: 25.2px;
}

.lh-27 {
    line-height: 27px;
}

.lh-288 {
    line-height: 28.8px;
}

.lh-30 {
    line-height: 30px;
}

.lh-3024 {
    line-height: 30.24px;
}

.lh-312 {
    line-height: 31.2px;
}

.lh-416 {
    line-height: 41.6px;
}

.lh-52 {
    line-height: 52px;
}


.family_Extra_light_100 {
    font-family: Extra_light;
}

.family_outfit_thin_200 {
    font-family: outfit_thin;
}

.family_outfit_light_300 {
    font-family: outfit_light;
}

.family_outfit_regular_400 {
    font-family: outfit_regular;
}

.family_outfit_medium_500 {
    font-family: outfit_medium;
}

.family_outfit_semiBold_600 {
    font-family: outfit_semiBold;
}

.family_outfit_bold_700 {
    font-family: outfit_bold;
}

.family_outfit_extraBold_800 {
    font-family: outfit_extraBold;
}

.family_outfit_black_900 {
    font-family: outfit_black;
}

.main-heading {
    font-size: 32px;
    font-weight: 600;
    line-height: 41.6px;
}

.login-card {
    min-height: 540px !important;
}

.otp-card {
    max-height: 540px !important;
}

.phone-label {
    font-size: 14px;
    font-weight: 400;
    line-height: 17.64px;
}

.custom-input {
    border: none !important;
    border-bottom: 1px solid black !important;
    border-radius: 0px !important;
    padding: 8px;
}

.custom-input :focus {
    border: none !important;
}

.custom-container {
    .flag-dropdown {
        background-color: transparent !important;
        border: none !important;
        border-bottom: 1px solid black !important;
        border-radius: 0px !important;
    }

    .form-control {
        width: 100% !important;
        height: 48px !important;
        font-size: 16px !important;
        font-weight: 600 !important;
        color: #998A7B !important;
    }
}

.custom-input-register {
    border: none !important;
    border-bottom: 1px solid #EFE3D7 !important;
    border-radius: 0px !important;
    padding: 8px;
}

.custom-container-register {
    .flag-dropdown {
        background-color: transparent !important;
        border: none !important;
        border-bottom: 1px solid #EFE3D7 !important;
        border-radius: 0px !important;
    }

    .form-control {
        width: 100% !important;
        height: 48px !important;
        font-size: 16px !important;
        font-weight: 600 !important;
        color: #998A7B !important;
    }
}

.button {
    background-color: #375241 !important;
    color: #FFFFFF !important;
    font-size: 18px !important;
    font-family: outfit_semiBold !important;
    line-height: 18px !important;
    height: 56px !important;
    border-radius: 0px !important;
    width: 100% !important;
    text-transform: none !important;
}

.button-disabled {
    background-color: #D9D9D9 !important;
    color: #B3B3B3 !important;
    font-size: 18px !important;
    font-family: outfit_semiBold !important;
    line-height: 18px !important;
    height: 56px !important;
    border-radius: 0px !important;
    width: 100% !important;
    text-transform: none !important;
}

.button-register {
    background-color: transparent !important;
    border: 1px solid #EFE3D7 !important;
    color: #3C3C3C !important;
    font-size: 18px !important;
    font-family: outfit_semiBold !important;
    line-height: 18px !important;
    height: 56px !important;
    border-radius: 0px !important;
    width: 100% !important;
    text-transform: none !important;
    box-shadow: none !important;
}

.footer-landing {
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    color: #7F7F7F;
}

.footer-sign {
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    color: #3C3C3C;
}

.uploadPhoto {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 1px solid #EFE3D7;
    background-color: #FEF9F6;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    @media screen and (max-width:640px) {
        width: 80px;
        height: 80px;
    }
}

.error-message {
    font-size: 14px;
    color: red;
    line-height: 24px;
    font-family: outfit_regular;
    margin-top: -3px;
    position: absolute;
}

.profession-detail {
    border: 1px solid #EFE3D7;
    min-height: 42px !important;
    background-color: #FEF9F6 !important;
    color: #864629 !important;
    display: flex;
    align-items: center;
}

.profession-detail-list {
    border: 1px solid #EFE3D7;
    border-top: none;
    min-height: 42px !important;
    color: #000000 !important;
    display: flex;
    align-items: center;
}

.profession-heading {
    border-right: 1px solid #EFE3D7 !important;
    min-height: 42px !important;
    display: flex;
    align-items: center;
}

.profession-heading-detail {
    border-right: 1px solid #EFE3D7 !important;
    min-height: 64px !important;
    display: flex;
    align-items: center;
}

.add-detail {
    border: 1px solid #EFE3D7 !important;
    width: 247px !important;
    height: 44px !important;
    color: #C56616 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.professional-card {
    max-height: 445px !important;
}

.succes-card {
    min-height: 649px !important;
    display: flex;
    align-items: center;
    justify-content: center;
}


.practice-box {
    min-width: 166px;
    min-height: 34px;
    padding: 8px 12px;
    border-radius: 8px;
    margin-top: 12px;
    cursor: pointer;
}

.review-box {
    border: 1px solid #EFE3D7;
}

.subscription-list {
    min-height: 450px !important;
}

.subscription-card {
    border-radius: 0px !important;
    border: 0.85px solid #E4E4E4 !important;

}

.subscription-card-select {
    border-radius: 0px !important;
    border: 1px solid #864629 !important;
    z-index: 2;
    // box-shadow: 0 8px 20px rgba(0, 0, 0, 2);
    box-shadow: 0 8px 20px rgba(164, 170, 178, 2);
}

.spinnerStyle {
    width: 73vw;
    margin-top: 40vh;
    text-align: center;
    display: flex !important;
    justify-content: center !important;
}

.modal-size {
    padding-top: 0;
    padding-right: 0 !important;
    padding-bottom: 0;

    @media screen and (max-width:991px) {
        padding: 0 !important;
    }
}

.button-remote-length {
    margin-top: -455px !important;
    overflow-y: hidden !important;
}

.button-remote {
    margin-top: 0px !important;
}

.editBtn {
    border-radius: 50%;
    position: relative;
    top: -40px;
    left: 75px;
}

.slot-box {
    padding: 8px 17px;
}

@media screen and (max-width:640px) {
    .custom-container {
        .form-control {
            background: transparent !important;
        }
    }
}

.size-padding {
    @media screen and (max-width:640px) {
        margin: 0px !important;
        padding: 0px !important;
    }
}

.size-padding-schedule {
    @media screen and (max-width:767px) {
        margin: 0px !important;
        padding: 0px !important;
    }
}
.dashboard-card {
    border-radius: 0px !important;
    border: 1px solid #EFE3D7 !important;
}

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before,
.slick-prev:before,
.slick-next:before {
    display: none !important;
    left: auto !important;
}

.sliderDetailsEn .slick-prev {
    filter: drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.3));
    width: 40px !important;
    height: 40px !important;
}

.sliderDetailsEn .slick-next {
    position: absolute !important;
    // top: -50px !important;
    right: 0px !important;
    filter: drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.3));
    width: 40px !important;
    height: 40px !important;
}

.sliderDetailsAr .slick-prev {
    position: absolute !important;
    top: -50px !important;
    left: 150px !important;
    width: 40px !important;
    height: 40px !important;
}

.sliderDetailsAr .slick-next {
    position: absolute !important;
    top: -50px !important;
    right: 100px !important;
    left: 10px !important;
    display: block;
    width: 40px !important;
    height: 40px !important;
}

.single-item-slider .slick-track {
    justify-content: flex-start !important;
    display: flex !important;
    margin-left: 0px !important;
}